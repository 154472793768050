import { gql } from "@apollo/client";

export const GET_INTERNET_PACKAGES = gql`
  query AdminInternetPackages {
    adminInternetPackages {
      id
      name
    }
  }
`;
