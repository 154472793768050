import { gql } from "@apollo/client";

export const GET_AVAILABLE_AGENTS = gql`
  query AvailableAgents(
    $amount: Float!
    $currency: CurrencyCode!
    $thirdPartyProvider: ThirdPartyProviderType!
    $transactionType: PaymentType!
  ) {
    availableAgents(
      amount: $amount
      transactionType: $transactionType
      currency: $currency
      thirdPartyProvider: $thirdPartyProvider
    ) {
      id
      account {
        name
      }
    }
  }
`;
