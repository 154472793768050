import { useMutation, useQuery } from "@apollo/client";
import { useNavigation, useNotification } from "@pankod/refine-core";
import { Create, TextInput, Select } from "@pankod/refine-mantine";
import { GET_AVAILABLE_AGENTS } from "graphql/agents/get-available-agents";
import { GET_PROVIDER_PREFERENCES } from "graphql/providerPrefereneces/get-provider-preferences.query";
import { GET_INTERNET_PACKAGES } from "graphql/internet-packages/get-internet-packages.query";
import { CREATE_TRANSACTION } from "graphql/transactions/create-transaction.mutation";
import { Currency } from "pages/exchange-rates/list";
import { toNumber } from "lodash";
import { useEffect, useState } from "react";

enum PaymentType {
  TRANSFER = "TRANSFER",
  CROSS_TRANSFER = "EXCHANGE",
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAWAL",
  INTERNET_TOPUP = "INTERNET_TOPUP",
}

enum ProviderType {
  ZAAD,
  EDAHAB,
  SOLTELCO,
  EVC,
}

interface TransferCreateInput {
  phoneNumberFrom: string;
  phoneNumberTo: string;
}

interface WithdrawalRequestThirdPartyProviderInput {
  provider: string;
  accountNumber: string;
  acccountName: string;
}

interface DepositRequestThirdPartyProviderInput
  extends Pick<WithdrawalRequestThirdPartyProviderInput, "provider"> {
  senderAccountNumber: string;
}

interface DepositorDetails {
  agentId: string;
  provider: string;
  senderAccountNumber: string;
}

interface WithdrawalDetails extends Pick<DepositorDetails, "provider"> {
  receiverAccountNumber: string;
  receiverAccountName: string;
}

interface WithdrawalCreateInput {
  thirdPartyProviderDetails: WithdrawalRequestThirdPartyProviderInput;
}

interface DepositCreateInput {
  agentId: string;
  thirdPartyProviderDetails: DepositRequestThirdPartyProviderInput;
}

interface CrossCreateInput {
  depositDetails: DepositorDetails;
  withdrawalDetails: WithdrawalDetails;
}

interface InternetDepositDetails
  extends Pick<DepositorDetails, "provider" | "agentId"> {}

interface InternetTopupDetails
  extends Pick<
    WithdrawalDetails,
    "receiverAccountNumber" | "receiverAccountName"
  > {}

interface InternetTopupInput {
  internetPackageId: string;
  depositDetails: InternetDepositDetails;
  internetTopupDetails: InternetTopupDetails;
}

interface CreateTransactionInput {
  paymentType: string;
  phoneNumber: string;
  amount: number;
  currencyCode: string;
  transferInput: TransferCreateInput;
  withdrawalInput: WithdrawalCreateInput;
  depositInput: DepositCreateInput;
  crossInput: CrossCreateInput;
  internetInput: InternetTopupInput;
}

const currencyTypes: string[] = Object.keys(Currency).filter((key) =>
  isNaN(Number(key))
);

const paymentTypes = Object.entries(PaymentType).map(([key, value]) => ({
  label: key,
  value,
}));

const providerTypes: string[] = Object.keys(ProviderType).filter((key) =>
  isNaN(Number(key))
);

export const TransactionCreate: React.FC = () => {
  const { push } = useNavigation();
  const { open } = useNotification();
  const [createTransaction, { loading }] = useMutation(CREATE_TRANSACTION);
  const [inputs, setInputs] = useState<CreateTransactionInput>({
    paymentType: "TRANSFER",
    phoneNumber: "",
    amount: 100,
    currencyCode: "SLSH",
    transferInput: {
      phoneNumberFrom: "",
      phoneNumberTo: "",
    },
    withdrawalInput: {
      thirdPartyProviderDetails: {
        provider: "ZAAD",
        accountNumber: "",
        acccountName: "",
      },
    },
    depositInput: {
      agentId: "",
      thirdPartyProviderDetails: {
        provider: "ZAAD",
        senderAccountNumber: "",
      },
    },
    crossInput: {
      depositDetails: {
        agentId: "",
        provider: "ZAAD",
        senderAccountNumber: "",
      },
      withdrawalDetails: {
        provider: "EDAHAB",
        receiverAccountNumber: "",
        receiverAccountName: "",
      },
    },
    internetInput: {
      internetPackageId: "",
      depositDetails: {
        agentId: "",
        provider: "EDAHAB",
      },
      internetTopupDetails: {
        receiverAccountNumber: "",
        receiverAccountName: "",
      },
    },
  });

  const { data: agents } = useQuery(GET_AVAILABLE_AGENTS, {
    variables: {
      amount: inputs.amount,
      currency: inputs.currencyCode,
      thirdPartyProvider: inputs.crossInput.depositDetails.provider,
      transactionType: "EXCHANGE",
    },
  });
  const agentIds = agents
    ? agents.availableAgents.map((agent: any) => {
        return {
          value: agent.id,
          label: agent.account.name,
        };
      })
    : [];

  const { data: providerPreferencesResult, refetch } = useQuery(
    GET_PROVIDER_PREFERENCES,
    {
      variables: {
        phoneNumber: inputs.phoneNumber,
      },
    }
  );

  const { data: internet } = useQuery(GET_INTERNET_PACKAGES);
  const internetPackages = internet
    ? internet.adminInternetPackages.map((internetPackage: any) => {
        return {
          value: internetPackage.id,
          label: internetPackage.name,
        };
      })
    : [];

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await createTransaction({
        variables: {
          input: {
            paymentType: inputs.paymentType,
            phoneNumber: inputs.phoneNumber,
            transferInput: {
              ...inputs.transferInput,
              amount: toNumber(inputs.amount),
              currencyCode: inputs.currencyCode,
            },
            withdrawalInput: {
              ...inputs.withdrawalInput,
              amount: toNumber(inputs.amount),
              currencyCode: inputs.currencyCode,
            },
            depositInput: {
              ...inputs.depositInput,
              amount: toNumber(inputs.amount),
              currencyCode: inputs.currencyCode,
            },
            crossInput: {
              ...inputs.crossInput,
              amount: toNumber(inputs.amount),
              currencyCode: inputs.currencyCode,
            },
            internetInput: {
              ...inputs.internetInput,
            },
          },
        },
      });
      open?.({
        type: "success",
        message: "Successfully created transaction ",
      });
      push("/transactions");
    } catch (e: any) {
      open?.({
        type: "error",
        message: e.message,
      });
    }
  };

  useEffect(() => {
    refetch();
  }, [inputs.phoneNumber, refetch]);

  return (
    <Create isLoading={loading} saveButtonProps={{ onClick: handleSubmit }}>
      <Select
        mt={8}
        zIndex={1000}
        label="PaymentType"
        placeholder="Select PaymentType"
        value={inputs.paymentType}
        onChange={(e: string) => {
          setInputs({
            ...inputs,
            paymentType: e,
          });
        }}
        data={paymentTypes}
      />

      {/* For Transfer */}
      {inputs.paymentType === "TRANSFER" && (
        <>
          <TextInput
            mt={8}
            placeholder="PhoneNumberFrom"
            label="PhoneNumberFrom"
            value={inputs.transferInput.phoneNumberFrom}
            defaultValue={"+252"}
            onChange={(e) =>
              setInputs({
                ...inputs,
                transferInput: {
                  ...inputs.transferInput,
                  phoneNumberFrom: e.target.value,
                },
              })
            }
          />
          <TextInput
            mt={8}
            placeholder="PhoneNumberTo"
            label="PhoneNumberTo"
            value={inputs.transferInput.phoneNumberTo}
            defaultValue={"+252"}
            onChange={(e) =>
              setInputs({
                ...inputs,
                transferInput: {
                  ...inputs.transferInput,
                  phoneNumberTo: e.target.value,
                },
              })
            }
          />
        </>
      )}

      {/* PhoneNumber For All Except Transfer */}
      {inputs.paymentType === "WITHDRAWAL" ||
      inputs.paymentType === "DEPOSIT" ||
      inputs.paymentType === "EXCHANGE" ||
      inputs.paymentType === "INTERNET_TOPUP" ? (
        <>
          <TextInput
            mt={8}
            placeholder="Phone Number"
            label="Phone Number"
            value={inputs.phoneNumber}
            defaultValue={"+252"}
            onChange={(e) => {
              setInputs({ ...inputs, phoneNumber: e.target.value });
            }}
          />

          <h3 className="mt-4">Account Preferences</h3>
          <h3 className="mt-1">
            Name:
            {providerPreferencesResult?.findProviderPreferences[0].fullName}
          </h3>
          {providerPreferencesResult?.findProviderPreferences.map(
            (preference: any) => (
              <TextInput
                mt={8}
                disabled
                className="w-1/5 inline-block mr-5"
                label={preference.providerName}
                value={preference.providerNumber}
              />
            )
          )}
        </>
      ) : null}

      {/* Agent For Deposit & Cross & INTERNET_TOPUP */}
      {inputs.paymentType === "DEPOSIT" ||
      inputs.paymentType === "EXCHANGE" ||
      inputs.paymentType === "INTERNET_TOPUP" ? (
        <>
          <Select
            mt={8}
            zIndex={1000}
            label="Agent"
            placeholder="Select Agent"
            value={inputs.crossInput.depositDetails.agentId}
            onChange={(e: string) => {
              setInputs({
                ...inputs,
                crossInput: {
                  ...inputs.crossInput,
                  depositDetails: {
                    ...inputs.crossInput.depositDetails,
                    agentId: e,
                  },
                },
                depositInput: {
                  ...inputs.depositInput,
                  agentId: e,
                },
                internetInput: {
                  ...inputs.internetInput,
                  depositDetails: {
                    ...inputs.internetInput.depositDetails,
                    agentId: e,
                  },
                },
              });
            }}
            data={agentIds}
          />
        </>
      ) : null}

      {/* Provider For Deposit & Withdrawal & Cross && InternetTopUp */}
      {inputs.paymentType === "WITHDRAWAL" ||
      inputs.paymentType === "DEPOSIT" ||
      inputs.paymentType === "EXCHANGE" ||
      inputs.paymentType === "INTERNET_TOPUP" ? (
        <>
          <Select
            mt={8}
            label="Provider"
            placeholder="Select provider"
            value={inputs.withdrawalInput.thirdPartyProviderDetails.provider} // Since they take same value we can choose one
            onChange={(e: string) => {
              if (!providerPreferencesResult?.findProviderPreferences)
                open?.({
                  type: "error",
                  message: "provide correct phone number first",
                });
              else
                setInputs({
                  ...inputs,
                  withdrawalInput: {
                    ...inputs.withdrawalInput,
                    thirdPartyProviderDetails: {
                      ...inputs.withdrawalInput.thirdPartyProviderDetails,
                      provider: e,
                    },
                  },
                  depositInput: {
                    ...inputs.depositInput,
                    thirdPartyProviderDetails: {
                      ...inputs.depositInput.thirdPartyProviderDetails,
                      provider: e,
                      // Set which number we are sending from automatically for Deposit & Cross
                      senderAccountNumber:
                        providerPreferencesResult.findProviderPreferences.find(
                          (provider: any) => provider.providerName === e
                        ).providerNumber,
                    },
                  },
                  crossInput: {
                    ...inputs.crossInput,
                    depositDetails: {
                      ...inputs.crossInput.depositDetails,
                      provider: e,
                      senderAccountNumber:
                        providerPreferencesResult.findProviderPreferences.find(
                          (provider: any) => provider.providerName === e
                        ).providerNumber,
                    },
                  },
                  internetInput: {
                    ...inputs.internetInput,
                    depositDetails: {
                      ...inputs.internetInput.depositDetails,
                      provider: e,
                    },
                  },
                });
            }}
            data={providerTypes}
          />
        </>
      ) : null}

      {/* For Withdrawal & InternetTopUp */}
      {inputs.paymentType === "WITHDRAWAL" ||
      inputs.paymentType === "INTERNET_TOPUP" ? (
        <>
          <TextInput
            mt={8}
            placeholder="Account Number"
            label="Account Number"
            value={
              inputs.withdrawalInput.thirdPartyProviderDetails.accountNumber
            }
            defaultValue={"+252"}
            onChange={(e) =>
              setInputs({
                ...inputs,
                withdrawalInput: {
                  ...inputs.withdrawalInput,
                  thirdPartyProviderDetails: {
                    ...inputs.withdrawalInput.thirdPartyProviderDetails,
                    accountNumber: e.target.value,
                  },
                },
                internetInput: {
                  ...inputs.internetInput,
                  internetTopupDetails: {
                    ...inputs.internetInput.internetTopupDetails,
                    receiverAccountNumber: e.target.value,
                  },
                },
              })
            }
          />
          <TextInput
            mt={8}
            placeholder="Account Name"
            label="Account Name"
            value={
              inputs.withdrawalInput.thirdPartyProviderDetails.acccountName
            }
            onChange={(e) =>
              setInputs({
                ...inputs,
                withdrawalInput: {
                  ...inputs.withdrawalInput,
                  thirdPartyProviderDetails: {
                    ...inputs.withdrawalInput.thirdPartyProviderDetails,
                    acccountName: e.target.value,
                  },
                },
                internetInput: {
                  ...inputs.internetInput,
                  internetTopupDetails: {
                    ...inputs.internetInput.internetTopupDetails,
                    receiverAccountName: e.target.value,
                  },
                },
              })
            }
          />
        </>
      ) : null}

      {/* For Cross */}
      {inputs.paymentType === "EXCHANGE" && (
        <>
          <Select
            mt={8}
            label="Withdrawal Provider"
            placeholder="Select Withdrawal provider"
            value={inputs.crossInput.withdrawalDetails.provider}
            onChange={(e: string) => {
              if (!providerPreferencesResult?.findProviderPreferences)
                open?.({
                  type: "error",
                  message: "provide phone number first",
                });
              else
                setInputs({
                  ...inputs,
                  crossInput: {
                    ...inputs.crossInput,
                    withdrawalDetails: {
                      ...inputs.crossInput.withdrawalDetails,
                      provider: e,
                      receiverAccountNumber:
                        providerPreferencesResult.findProviderPreferences.find(
                          (provider: any) => provider.providerName === e
                        ).providerNumber,
                    },
                  },
                });
            }}
            data={providerTypes}
          />
        </>
      )}

      {/* For Internet Topup */}
      {inputs.paymentType === "INTERNET_TOPUP" && (
        <>
          <Select
            mt={8}
            label="Internet Package"
            placeholder="Select Internet Package"
            value={inputs.internetInput.internetPackageId}
            onChange={(e: string) =>
              setInputs({
                ...inputs,
                internetInput: {
                  ...inputs.internetInput,
                  internetPackageId: e,
                },
              })
            }
            data={internetPackages}
          />
        </>
      )}

      {/* For All Except InternetTopUp */}
      {inputs.paymentType !== "INTERNET_TOPUP" && (
        <>
          <TextInput
            mt={8}
            placeholder="Amount"
            label="Amount"
            value={inputs.amount}
            onChange={(e) =>
              setInputs({
                ...inputs,
                amount: +e.target.value,
              })
            }
          />
          <Select
            mt={8}
            label="Currency"
            placeholder="Select Currency Code"
            value={inputs.currencyCode}
            onChange={(e: string) =>
              setInputs({
                ...inputs,
                currencyCode: e,
              })
            }
            data={currencyTypes}
          />
        </>
      )}
    </Create>
  );
};
